import styled, { type StyledProps } from "styled-components";
import { LAYOUT } from "@/constants/styles";
import { getFontStyles } from "@/redux/cmsConfig/utils";

export const StyledAuthPageLayout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - ${LAYOUT.HEADERHEIGHT * 2}px);

  @media (max-width: 767px) {
    background-color: ${({ theme }: StyledProps<{}>) =>
      theme.auth_page.background_color};
    min-height: calc(100vh - ${LAYOUT.MOBILE_HEADERHEIGHT * 2}px);
  }
`;

export const StyledAuthPageContent = styled.div(({ theme }) => ({
  backgroundColor: theme.auth_page.background_color ?? "transparent",
  border: `1px solid ${theme.auth_page.border_color ?? "transparent"}`,
  borderRadius: `${theme.auth_page.border_radius ?? 0}px`,
  margin: "0 auto",
  maxWidth: `${LAYOUT.MEDIUM_CONTENT}px`,
  padding: "40px 20px",
  textAlign: "center",
  width: "100%",
  ...getFontStyles(theme.auth_page.font),

  h1: {
    margin: 0,
    ...getFontStyles(theme.auth_page.secondary_font),
  },

  h3: {
    ...getFontStyles(theme.auth_page.font, [
      "color",
      "font_family",
      "font_size",
      "font_style",
      "line_height",
    ]),
    fontWeight: "normal",
    margin: 0,
  },

  p: {
    margin: "20px 0 0",
  },

  a: {
    textDecoration: "underline",
    transition: "opacity 0.2s linear",
    ...getFontStyles(theme.auth_page.tertiary_font),

    "&:hover": {
      opacity: 0.7,
    },
  },

  "@media (max-width: 767px)": {
    borderColor: theme.auth_page.background_color ?? "transparent",
  },
}));
