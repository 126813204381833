import { Component, createRef } from "react";
import ReactReCAPTCHA from "react-google-recaptcha";
import { type ConnectedProps, connect } from "react-redux";
import { type CAPTCHA_ACTIONS } from "@/constants/authentication";
import { type RootState } from "@/types/app";
import * as ErrorReporter from "@/utils/errorReporter";

export type ReCaptchaRef = React.RefObject<ReactReCAPTCHA>;

interface Props extends ReduxProps {
  onRef: (ref: ReCaptchaRef) => void;
  action: CAPTCHA_ACTIONS;
  forwardRef: ReCaptchaRef | undefined;
}

class ReCaptcha extends Component<Props> {
  componentDidMount() {
    this.props.onRef(createRef<ReactReCAPTCHA>());
  }

  render() {
    const { forwardRef, organization, action } = this.props;
    const sitekey = organization?.external_auth?.captcha?.site_key;
    const actions = organization?.external_auth?.captcha?.actions || [];

    // Ensures we show captcha conditionally to companies that have it enabled.
    if (!sitekey || !actions.includes(action)) {
      return null;
    }

    return (
      <ReactReCAPTCHA
        ref={forwardRef}
        onError={ErrorReporter.captureException}
        sitekey={sitekey}
        badge="bottomright"
        size="invisible"
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  organization: state.app.organization,
});

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(ReCaptcha);
